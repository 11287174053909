import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BusGroupPage from './components/BusGroupPage';
import BusSinglePage from './components/BusSinglePage';
import URLPage from './components/URLPage';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/list" element={<URLPage />} />
        <Route path="/bus/:busUuid" element={<BusSinglePage />} />
        <Route path="/:groupName" element={<BusGroupPage />} />
      </Routes>
    </Router>
  );
};

export default App;
