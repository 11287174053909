import React from 'react';
import SidebarMenuTitle from './sidebarMenuTitle';
import SidebarMenuQuestionnaireButton from './sidebarMenuQuestionnaireButton';
import SidebarMenuContent from './sidebarMenuContent';
import SidebarMenuAnnounce from './sidebarMenuAnnounce';

interface SidebarMenuProps {
  isOpen: boolean;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isOpen }) => {

  return (
    <div
      className={`fixed top-0 right-0 h-full w-80 bg-gray-200 shadow-lg transform transition-transform duration-500 z-1400 ease-in-out opacity-90 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="max-w-[280px] mx-auto flex flex-col justify-between h-full">
        <div>
          <div>
            <SidebarMenuTitle />
          </div>
          <div>
            <SidebarMenuQuestionnaireButton />
          </div>
          <div>
            <SidebarMenuContent />
          </div>
        </div>
        <div className='mb-4'>
          <SidebarMenuAnnounce />
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
