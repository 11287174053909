import React from 'react';
import ParkingAreaMarker from './parkingAreaMarker';

interface ParkingAreaMarkerGroupProps {
  groupName: string;
}

const ParkingAreaMarkerGroup: React.FC<ParkingAreaMarkerGroupProps> = ({ groupName }) => {
  switch (groupName) {
    case 'sakura':
      return <ParkingAreaMarker 
                name="弘前りんご公園駐車場"
                position={[40.590002214906, 140.443788470325]}
                googleUrl="https://google.co.jp/maps/search/%E3%82%8A%E3%82%93%E3%81%94%E5%85%AC%E5%9C%92/@40.5902147,140.4430171,191m/data=!3m1!1e3?hl=ja&entry=ttu"
                popupImage="https://dl.dropbox.com/scl/fi/iqc29c7tgwczeyllc34xn/RingoPark.jpg?rlkey=4h7m6957j0jcgai3cqrqcbjru"
                popupMsg="注意：りんご公園は１７時で閉鎖になります。利用者は１７時までにお戻りください。５月４日〜６日はりんご公園では弘前りんご花まつりが開催され、りんご公園駐車場は満杯になりますので、パークアンドライドとしては使用できません。"
            />;
    // 他のグループに応じて追加
    default:
      return null;
  }
};

export default ParkingAreaMarkerGroup;
