import { createSlice } from '@reduxjs/toolkit';

interface QuestionnaireState {
  showQuestionnaire: boolean;
}

const initialState: QuestionnaireState = {
  showQuestionnaire: true,
};

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    openQuestionnaire: (state) => {
      state.showQuestionnaire = true;
    },
    closeQuestionnaire: (state) => {
      state.showQuestionnaire = false;
    },
  },
});

export const { openQuestionnaire, closeQuestionnaire } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;