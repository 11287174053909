import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { BusGroup } from '../api/getBusLocationType';
import busIcon from '../assets/images/busIcon.png';

interface BusMarkersProps {
  busLocations: BusGroup | null;
}

const BusMarkers: React.FC<BusMarkersProps> = ({ busLocations }) => {
  const icon = L.icon({
    iconUrl: busIcon,
    iconSize: [38, 38],
  });

  const [timeDifferences, setTimeDifferences] = useState<string[]>([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (busLocations) {
        const newTimeDifferences = busLocations.map((bus) =>
          formatTimeDifference(bus.acceptedAt)
        );
        setTimeDifferences(newTimeDifferences);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [busLocations]);

  const formatTimeDifference = (acceptedAt: string) => {
    const updatedTime = new Date(acceptedAt).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - updatedTime;

    const seconds = Math.floor(timeDifference / 1000);

    return `${seconds}秒前`;
  };

  return (
    <>
      {busLocations?.map((bus, index) => (
        <Marker key={index} position={[bus.latitude, bus.longitude]} icon={icon}>
          <Popup>
            <p>更新時刻: {timeDifferences[index] || '計算中...'}</p>
          </Popup>
        </Marker>
      ))}
    </>
  );
};

export default BusMarkers;