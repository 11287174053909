import React from 'react';
import { Popup } from 'react-leaflet';

interface DesktopPopupProps {
  name: string;
  googleUrl?: string;
  popupImage?: string;
  popupMsg?: string;
}

const DesktopPopup: React.FC<DesktopPopupProps> = ({ name, googleUrl, popupImage, popupMsg }) => {
  return (
    <Popup className="w-[800px] p-auto">
      <div className="mx-4 w-[700px] items-center">
        <h3 className="text-lg font-semibold mb-auto">{name}</h3>
        <a
          href={googleUrl}
          className="text-sm mb-4 block"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Mapsで開く
        </a>
        {popupImage && (
          <img
            alt="RingoPark.jpg"
            src={popupImage}
            className="w-[700px] object-contain mb-2"
          />
        )}
        {popupMsg && <p className="text-sm break-words">{popupMsg}</p>}
      </div>
    </Popup>
  );
};

export default DesktopPopup;