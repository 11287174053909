import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';

const SidebarMenuTitle: React.FC = () => {
    const groupName = useGetGroupName();

    switch (groupName) {
        case 'sakura':
            return (
                <div className="flex flex-col justify-between py-4">
                    <h2 className="text-2xl font-semibold tracking-wide text-center">
                        弘南バス
                        <br />
                        ロケーションシステム
                    </h2>
                    <div className="w-full h-1 bg-black rounded-full mt-4"></div>
                </div>
            );
        case 'inakadate':
            return (
                <div className="flex flex-col justify-between py-4">
                    <h2 className="text-2xl font-semibold tracking-wide text-center">
                        田舎館村田んぼアート
                        <br />
                        会場間シャトルバスロケ
                    </h2>
                    <div className="w-full h-1 bg-black rounded-full mt-4"></div>
                </div>
            );
        case 'hokusei':
            return (
                <div className="flex flex-col justify-between py-4">
                    <h2 className="text-2xl font-semibold tracking-wide text-center">
                        北星交通
                        <br />
                        堀越線
                    </h2>
                    <div className="w-full h-1 bg-black rounded-full mt-4"></div>
                </div>
            );
        case 'ajibus':
            return (
                <div className="flex flex-col justify-between py-4">
                    <h2 className="text-2xl font-semibold tracking-wide text-center">
                        あじバス
                        <br />
                        位置情報発信
                    </h2>
                    <div className="w-full h-1 bg-black rounded-full mt-4"></div>
                </div>
            );
        default:
            return null;
    }
}

export default SidebarMenuTitle;
