import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import busStopIcon from '../assets/images/busStopIcon.png';
import { getBusStops, BusStop } from '../utils/busStop';
import { useSelectedRoute } from '../hooks/useSelectedRoute';

interface BusStopMarkerProps {
  groupName: string;
}

const BusStopMarker: React.FC<BusStopMarkerProps> = ({ groupName }) => {
  const [busStopsData, setBusStopsData] = useState<{ [key: string]: BusStop[] }>({});
  const { selectedRoute } = useSelectedRoute();

  useEffect(() => {
    const fetchBusStops = () => {
      const busStopData: { [key: string]: BusStop[] } = {};
      if (groupName === 'sakura') {
        busStopData['dotemachi'] = getBusStops('dotemachi');
        busStopData['soma'] = getBusStops('soma');
      } else if (groupName === 'inakadate') {
        busStopData['inakadate'] = getBusStops('inakadate');
      }
      setBusStopsData(busStopData);
    };
    fetchBusStops();
  }, [groupName]);

  const markerIcon = L.icon({
    iconUrl: busStopIcon,
    iconSize: [40, 40],
  });

  const busStopsDotemachi = busStopsData['dotemachi'] || [];
  const busStopsSoma = busStopsData['soma'] || [];
  const busStopsInakadate = busStopsData['inakadate'] || [];

  return (
    <>
      {(selectedRoute === 'all' || selectedRoute === 'dotemachi') && groupName === 'sakura' &&
        busStopsDotemachi.map((busStop: BusStop) => (
          <Marker key={busStop.id} position={[busStop.stop_lat, busStop.stop_lon]} icon={markerIcon}>
            <Popup>
              <div className="bg-red-200 text-black p-4 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-2">土手町循環線</h2>
                <h2 className="font-bold text-xl mb-2">{busStop.stop_name}</h2>
                <p className="text-gray-700">
                  <span className="font-semibold">運行時間：</span>10：00～18：00
                </p>
                <p className="text-gray-700">
                  <span className="font-semibold">運行間隔：</span>10分間隔
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      {(selectedRoute === 'all' || selectedRoute === 'soma') && groupName === 'sakura' &&
        busStopsSoma.map((busStop: BusStop) => (
          <Marker key={busStop.id} position={[busStop.stop_lat, busStop.stop_lon]} icon={markerIcon}>
            <Popup>
              <div className="bg-purple-200 text-black p-4 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-2">相馬線</h2>
                <h3 className="font-bold text-xl mb-2">{busStop.stop_name}</h3>
                <a href={busStop.popupContent} target="_blank" rel="noopener noreferrer" className="underline text-black">
                  時刻表を開く
                </a>
              </div>
            </Popup>
          </Marker>
        ))}
      {(selectedRoute === 'all' || selectedRoute === 'inakadate') && groupName === 'inakadate' &&
        busStopsInakadate.map((busStop: BusStop) => (
          <Marker key={busStop.id} position={[busStop.stop_lat, busStop.stop_lon]} icon={markerIcon}>
            <Popup>
              <div className="bg-green-200 text-black p-4 rounded-lg shadow-lg">
              <h3 className="font-bold text-xl mb-2" style={{ width: '10ch', wordBreak: 'break-all' }}>{busStop.stop_name}</h3>
                <a href={busStop.popupContent} target="_blank" rel="noopener noreferrer" className="underline text-black">
                  時刻表を開く
                </a>
              </div>
            </Popup>
          </Marker>
        ))}
      {selectedRoute === 'none' && <></>}
    </>
  );
};

export default BusStopMarker;