import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRoute } from '../store/slices/pulldownSlice';
import { RootState } from '../store/index';

export const useSelectedRoute = () => {
  const dispatch = useDispatch();
  const selectedRoute = useSelector((state: RootState) => state.Pulldown.selectedRoute);

  const handleRouteChange = (route: string) => {
    dispatch(setSelectedRoute(route));
  };

  return { selectedRoute, handleRouteChange };
};
