import { useState } from 'react';

export const useMenuState = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return { isSidebarOpen, toggleMenu };
};