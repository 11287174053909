import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PulldownState {
  selectedRoute: string;
}

const initialState: PulldownState = {
  selectedRoute: 'all',
};

const pulldownSlice = createSlice({
  name: 'pulldown',
  initialState,
  reducers: {
    setSelectedRoute(state, action: PayloadAction<string>) {
      state.selectedRoute = action.payload;
    },
  },
});

export const { setSelectedRoute } = pulldownSlice.actions;

export default pulldownSlice.reducer;