import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';

const SidebarMenuAnnounce: React.FC = () => {
    const groupName = useGetGroupName();

    switch (groupName) {
        case 'sakura':
            return (
                <div>
                    <p className="font-normal text-sm text-gray-900">
                        注意事項：
                    </p>
                    <ul className="list-disc list-inside">
                        <p className="font-light text-xs text-red-500">
                            りんご公園は１７時で閉鎖になります。利用者は１７時までにお戻りください。５月４日〜６日はりんご公園では弘前りんご花まつりが開催され、りんご公園駐車場は満杯になりますので、パークアンドライドとしては使用できません。
                        </p>
                    </ul>
                </div>
            );
        // 他のグループに応じて追加
        default:
            return null;
    }
};

export default SidebarMenuAnnounce;