import { LatLngExpression } from 'leaflet';
import mapConfig from '../config/mapConfig.json';

export interface Line {
  name: string;
  color: string;
  coordinates: LatLngExpression[];
}

export interface MapConfig {
  center: LatLngExpression;
  zoom: number;
  minZoom: number;
  lines?: Line[];
}

interface MapConfigData {
  sakura: MapConfig;
  inakadate: MapConfig;
  hokusei: MapConfig;
  ajibus: MapConfig;
  [key: string]: MapConfig;
}

const mapConfigData = mapConfig as MapConfigData;

export const getMapConfig = (groupName: string): MapConfig | undefined => {
  return mapConfigData[groupName];
};