import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/index';
import { setGroupName } from '../store/slices/groupNameSlice';

export const useUpdateGroupName = (groupName: string | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupName) {
      dispatch(setGroupName(groupName));
    }
  }, [groupName, dispatch]);
}

export const useGetGroupName = () => {
    const groupName = useSelector((state: RootState) => state.groupName.value);
    return groupName;
}