import { useEffect, useState } from 'react';
import { getSingleBusLocation, getGroupBusLocations } from '../api/getBusLocation';
import { Bus, BusGroup } from '../api/getBusLocationType';

const API_CALL_INTERVAL: number = 10000; // 10秒ごとにAPIを叩く

export const useBusLocation = (busUuid: string): Bus | null => {
  const [location, setLocation] = useState<Bus | null>(null);

  useEffect(() => {
    if (busUuid) {
      // ページを開いたときにすぐにAPIを呼び出す
      getSingleBusLocation(busUuid).then(data => setLocation(data));
  
      const intervalId = setInterval(() => {
        getSingleBusLocation(busUuid).then(data => setLocation(data));
      }, API_CALL_INTERVAL);

      // コンポーネントがアンマウントされるときにタイマーをクリアする
      return () => clearInterval(intervalId);
    }
  }, [busUuid]);

  return location;
}

export const useBusLocations = (groupName: string): BusGroup | null => {
  const [location, setLocation] = useState<BusGroup | null>(null);

  useEffect(() => {
    if (groupName) {
      // ページを開いたときにすぐにAPIを呼び出す
      getGroupBusLocations(groupName).then(data => setLocation(data));
  
      const intervalId = setInterval(() => {
        getGroupBusLocations(groupName).then(data => setLocation(data));
      }, API_CALL_INTERVAL);

      // コンポーネントがアンマウントされるときにタイマーをクリアする
      return () => clearInterval(intervalId);
    }
  }, [groupName]);

  return location;
}