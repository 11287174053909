import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';

interface Content {
  title: string;
  link: string;
  icon: React.ReactNode;
}

const sakuraContents: Content[] = [
  {
    title: '開花情報',
    link: 'https://www.hirosakipark.jp/sakura/category/cherryblossom/',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mr-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>
    ),
  },
  {
    title: '見どころ',
    link: 'https://www.hirosakipark.jp/sakura/hanami/',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
      </svg>
    ),
  },
];

const inakadateContents: Content[] = [
  {
    title: '公式サイト',
    link: 'http://www.vill.inakadate.lg.jp',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
      </svg>
    ),
  },
  {
    title: '時刻表',
    link: 'http://www.vill.inakadate.lg.jp/docs/2022050600021/files/jikokuhyo.pdf',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 6V5a3 3 0 00-6 0v1H5a2 2 0 00-2 2v9a2 2 0 002 2h1a2 2 0 004 0h4a2 2 0 004 0h1a2 2 0 002-2v-9a2 2 0 00-2-2h-5zm-4-1a1 1 0 112 0v1h-2V5zM7 10h10v5H7v-5z" />
      </svg>
    ),
  }
];

const hokuseiContents: Content[] = [
  {
    title: '北星交通',
    link: 'https://www.hokuseikotsu.co.jp/',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
      </svg>
    ),
  },
  {
    title: '時刻表',
    link: 'https://www.hokuseikotsu.co.jp/pdf/timetable12.pdf',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 6V5a3 3 0 00-6 0v1H5a2 2 0 00-2 2v9a2 2 0 002 2h1a2 2 0 004 0h4a2 2 0 004 0h1a2 2 0 002-2v-9a2 2 0 00-2-2h-5zm-4-1a1 1 0 112 0v1h-2V5zM7 10h10v5H7v-5z" />
      </svg>
    ),
  }
];

const ajibusContents: Content[] = [
  {
    title: 'あじバスとは',
    link: 'https://www.town.ajigasawa.lg.jp/kurashi/seikatsu/ajibus_shokai.html',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
      </svg>
    )
  },
  {
    title: '全路線時刻表',
    link:  'https://www.town.ajigasawa.lg.jp/kurashi/seikatsu/ajibus_shokai.files/2024ajibusjikokuhyo.pdf',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 6V5a3 3 0 00-6 0v1H5a2 2 0 00-2 2v9a2 2 0 002 2h1a2 2 0 004 0h4a2 2 0 004 0h1a2 2 0 002-2v-9a2 2 0 00-2-2h-5zm-4-1a1 1 0 112 0v1h-2V5zM7 10h10v5H7v-5z" />
      </svg>
    )
  }
];

const SidebarMenuContent: React.FC = () => {
  const groupName = useGetGroupName();

  let contents: Content[] = [];
  let title = '';

  switch (groupName) {
    case 'sakura':
      title = '弘前さくらまつり情報';
      contents = sakuraContents;
      break;
    case 'inakadate':
      title = '田んぼアート情報';
      contents = inakadateContents;
      break;
    case 'hokusei':
      title = '北星交通情報';
      contents = hokuseiContents;
      break;
    case 'ajibus':
      title = 'あじバス情報';
      contents = ajibusContents;
      break;
    // Add a case for the new group
    default:
      return null;
  }

  return (
    <div className="py-4">
      <p className="mt-4 text-lg">{title}</p>
      <ul className="space-y-2">
        {contents.map((content, index) => (
          <li key={index}>
            <a
              href={content.link}
              className="flex items-center px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors duration-200"
            >
              {content.icon}
              <p className="font-light text-sm">{content.title}</p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarMenuContent;