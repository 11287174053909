import { configureStore } from '@reduxjs/toolkit';
import groupNameReducer from './slices/groupNameSlice';
import questionnaireReducer from './slices/questionnaireSlice';
import pulldownReducer from './slices/pulldownSlice';

export const store = configureStore({
  reducer: {
    groupName: groupNameReducer,
    questionnaire: questionnaireReducer,
    Pulldown: pulldownReducer,
    // 他のスライスのリデューサーもここに追加
  },
});

// RootStateの型をエクスポート（selectorsで使用）
export type RootState = ReturnType<typeof store.getState>;
// AppDispatch型をエクスポート（useDispatchカスタムフックで使用）
export type AppDispatch = typeof store.dispatch;
