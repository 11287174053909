import React from 'react';
import { useGetGroupName } from '../hooks/useGroupName';
import { useSelectedRoute } from '../hooks/useSelectedRoute';

const Pulldown: React.FC = () => {
  const groupName = useGetGroupName();
  const { selectedRoute, handleRouteChange } = useSelectedRoute();

  const getColor = (route: string) => {
    switch (route) {
      case 'none':
        return 'bg-gray-300';
      case 'all':
        return 'bg-orange-300';
      case 'dotemachi':
        return 'bg-red-300';
      case 'soma':
        return 'bg-purple-300';
      default:
        return 'bg-orange-300';
    }
  };

  const renderOptions = () => {
    switch (groupName) {
      case 'sakura':
        return (
          <>
            <option value="all">バス停表示</option>
            <option value="dotemachi">土手町循環線</option>
            <option value="soma">相馬・西目屋線</option>
            <option value="none">バス停非表示</option>
          </>
        );
      // 他のgroupNameの処理をここに追加
      default:
        return null;
    }
  }

  // 定義外のgroupNameの場合はpulldownコンポーネントを表示させない
  const options = renderOptions();
  if (!options) {
    return null;
  }

  return (
    <select
    value={selectedRoute}
    onChange={(event) => handleRouteChange(event.target.value)}
    className={`${getColor(selectedRoute)} text-gray-700 text-center font-bold py-2 px-4 rounded mb-2 h-9 w-40`}
    style={{ textAlignLast: 'center' }}  // 直接指定しないと本番環境のiPhoneで文字が中央に表示されない
    >
    {renderOptions()}
    </select>
  );
};

export default Pulldown;