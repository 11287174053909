import React, { useState } from 'react';
import QRCode from 'qrcode.react';

const URLPage: React.FC = () => {
  const urls = [
    { title: '弘南バス', url: 'https://loc.koustate.com/sakura' },
    { title: 'おでかけ', url: 'https://loc.koustate.com/outgoing' },
    { title: '田舎館バス', url: 'https://loc.koustate.com/inakadate' },
    { title: '北星交通', url: 'https://loc.koustate.com/hokusei' },
    { title: 'あじバス', url: 'https://loc.koustate.com/ajibus' },
    { title: '田んぼアート', url: 'https://public.koustate.com/tanboart' }
  ];

  // QRコードの表示状態を管理するための状態
  const [visibleQR, setVisibleQR] = useState(Array(urls.length).fill(false));

  // QRコードの表示/非表示を切り替える関数
  const toggleQR = (index: number) => {
    const newVisibleQR = [...visibleQR];
    newVisibleQR[index] = !newVisibleQR[index];
    setVisibleQR(newVisibleQR);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">公開サイト一覧</h2>
        </div>
        <div className="rounded-lg shadow-md -space-y-px">
          {urls.map((item, index) => (
            <div key={index} className={`${index === 0 ? 'rounded-t-lg' : ''} ${index === urls.length - 1 ? 'rounded-b-lg' : ''} px-4 py-5 border-0 border-gray-200`}>
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-medium text-black">{item.title}</h3>
                  <a href={item.url} className="text-blue-500">{item.url}</a>
                </div>
                <button 
                  onClick={() => toggleQR(index)} 
                  className={`py-2 px-4 text-white rounded ${visibleQR[index] ? 'bg-red-400' : 'bg-green-500'}`}>
                  {visibleQR[index] ? 'QRを隠す' : 'QRを表示'}
                </button>
              </div>
              {visibleQR[index] && <div className="mt-2 flex justify-center"><QRCode value={item.url} size={128} /></div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default URLPage;