import React from 'react';
import { useParams } from 'react-router-dom';
import { useBusLocation } from '../hooks/useBusLocation';
import { Bus } from '../api/getBusLocationType';

const BusSinglePage: React.FC = () => {
  const { busUuid } = useParams<{ busUuid: string | undefined }>();
  const busLocation: Bus | null = useBusLocation(busUuid || '');

  return (
    <div>
      <h1>Bus Single Location</h1>
      <h3>{busUuid}</h3>
      {busLocation && (
        <div>
          <p>name: {busLocation.name}</p>
          <p>Latitude: {busLocation.latitude}</p>
          <p>Longitude: {busLocation.longitude}</p>
          <p>time: {busLocation.acceptedAt}</p>
          <br></br>
        </div>
      )}
    </div>
  );
}

export default BusSinglePage;
