import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import userIcon from '../assets/images/userIcon.png';

const UserLocationMarker: React.FC = () => {
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition((position) => {
      setUserLocation([position.coords.latitude, position.coords.longitude]);
    });
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  const icon = L.icon({
    iconUrl: userIcon,
    iconSize: [45, 45],
  });

  return userLocation ? (
    <Marker position={userLocation} icon={icon}>
      <Popup>
        <div className="bg-white text-black p-0.05 rounded-lg">
          <h2 className="text-sm mb-0.05">現在位置</h2>
        </div>
      </Popup>
    </Marker>
  ) : null;
};

export default UserLocationMarker;
