import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { closeQuestionnaire } from '../store/slices/questionnaireSlice';

interface FormData {
  name: string;
  radio: string;
  message: string;
}

const FormKeys = {
  formUrl: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScydillgD17z4ZM4z1Rg9TIAcjgoGF79T6TyZnWEpDTpL1Geg/formResponse',
  name: 'entry.479427668',
  radio: 'entry.1063945392',
  message: 'entry.480410305',
};

const QuestionnaireForm = () => {
  const showQuestionnaire = useSelector((state: RootState) => state.questionnaire.showQuestionnaire);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showQuestionnaire) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showQuestionnaire]);

  const handleOverlayWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const body = new URLSearchParams({
      [FormKeys.name]: data.name,
      [FormKeys.radio]: data.radio,
      [FormKeys.message]: data.message,
    }).toString();
    fetch(FormKeys.formUrl, {
      method: 'POST',
      mode: 'no-cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })
      .then(() => {
        dispatch(closeQuestionnaire());
        toast.success('送信に成功しました', {
          position: "top-right",
          autoClose: 1500,
        });
      })
      .catch((error) => {
        toast.error('送信に失敗しました', {
          position: "top-right",
          autoClose: 1500,
        });
      });
  };

  if (!showQuestionnaire) return null;

  return (
    <>
      <div>
        <div
          className="fixed inset-0 bg-black bg-opacity-50"
          onClick={() => dispatch(closeQuestionnaire())}
          onWheel={handleOverlayWheel}
          style={{ zIndex: 1500 }}
        ></div>
        <div
          id="default-modal"
          aria-hidden="true"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full items-center justify-center flex"
          style={{ zIndex: 2000 }}
        >
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex items-center justify-between px-4 pt-4">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-200 dark:hover:text-white"
                  onClick={() => dispatch(closeQuestionnaire())}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="mx-auto max-w-5xl px-4">
                <h2 className="text-center text-2xl text-black md:text-3xl">アンケート</h2>
                <p className="text-center text-black">ご利用いただき、ありがとうございます。</p>
              </div>
              <div className="mx-[3vw] rounded-xl bg-white p-6 md:mx-auto md:max-w-3xl">
                <div className="md:mx-auto md:max-w-lg">
                  <p className="mb-2 md:mb-6 text-black">
                    <span className="text-[#F0372C]">*</span>は入力必須項目です。
                  </p>
                </div>
                <div className="md:mx-auto md:max-w-lg">
                  <form method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-4">
                      <div className="flex">
                        <label className="text-black">
                          お名前（省略可）
                        </label>
                      </div>
                      <div>
                        <input
                          {...register('name')}
                          className="mt-1 block w-full rounded-lg border border-gray-300 p-3"
                          placeholder="山田　太郎"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex">
                        <label className="text-black">
                          満足度<span className="text-red-500">*</span>
                        </label>
                        {errors.radio && <span className="ml-3 text-red-500">選択してください</span>}
                      </div>
                      <div className="flex justify-between mt-2">
                        <label className="flex items-center">
                          <input {...register('radio', { required: true })} value="1" type="radio" className="mr-2" />
                          良い
                        </label>
                        <label className="flex items-center">
                          <input {...register('radio', { required: true })} value="2" type="radio" className="mr-2" />
                          普通
                        </label>
                        <label className="flex items-center">
                          <input {...register('radio', { required: true })} value="3" type="radio" className="mr-2" />
                          悪い
                        </label>
                      </div>
                    </div>
                    <div className="mb-6 md:mb-10">
                      <div className="flex">
                        <label className="text-black">
                          ご意見・ご要望
                        </label>
                      </div>
                      <div>
                        <textarea
                          {...register('message')}
                          className="mt-1 block w-full rounded-lg border border-gray-300 p-3"
                          placeholder="内容を記入してください。"
                          rows={5}
                        />
                      </div>
                    </div>
                    <button className="md:mb-4 w-full rounded bg-[#F0372C] py-4 text-white" type="submit">
                      送信
                    </button>
                  </form>
                  <iframe className="hidden" name="dummyIframe" title="Hidden iframe for form submission"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireForm;
