import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import parkingAreaIcon from '../assets/images/parkingAreaIcon.jpg';
import DesktopPopup from './desktopPopup';
import MobilePopup from './mobilePopup';
import { useIsMobile } from '../hooks/useIsMobile';

interface ParkingAreaMarkerProps {
  name: string;
  position: [number, number];
  googleUrl?: string;
  popupImage?: string;
  popupMsg?: string;
}

const ParkingAreaMarker: React.FC<ParkingAreaMarkerProps> = ({ name, position, googleUrl, popupImage, popupMsg }) => {
  const isMobile = useIsMobile();

  const markerIcon = L.icon({
    iconUrl: parkingAreaIcon,
    iconSize: [40, 40],
  });

  return (
    <Marker position={position} icon={markerIcon}>
      {isMobile ? (
        <MobilePopup name={name} googleUrl={googleUrl} popupImage={popupImage} popupMsg={popupMsg} />
      ) : (
        <DesktopPopup name={name} googleUrl={googleUrl} popupImage={popupImage} popupMsg={popupMsg} />
      )}
    </Marker>
  );
};

export default ParkingAreaMarker;
