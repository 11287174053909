import React from 'react';
import { Popup } from 'react-leaflet';

interface MobilePopupProps {
  name: string;
  googleUrl?: string;
  popupImage?: string;
  popupMsg?: string;
}

const MobilePopup: React.FC<MobilePopupProps> = ({ name, googleUrl, popupImage, popupMsg }) => {
  return (
    <Popup className="w-90">
      <h3 className="text-lg font-semibold mb-2">{name}</h3>
      <a href={googleUrl} className="text-sm mb-2 block">
        Google Mapsで開く
      </a>
      {popupImage && (
        <img
          alt="RingoPark.jpg"
          src={popupImage}
          className="w-full h-auto object-contain mb-2"
        />
      )}
      {popupMsg && <p className="text-sm break-words">{popupMsg}</p>}
    </Popup>
  );
};

export default MobilePopup;