import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GroupNameState {
  value: string;
}

const initialState: GroupNameState = {
  value: '',
};

export const groupNameSlice = createSlice({
  name: 'groupName',
  initialState,
  reducers: {
    setGroupName: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setGroupName } = groupNameSlice.actions;

export default groupNameSlice.reducer;
