import busStop_dotemachi from '../config/busStop_dotemachi.json';
import busStop_soma from '../config/busStop_soma.json';
import busStop_inakadate from '../config/busStop_inakadate.json';

export interface BusStop {
    id: string;
    stop_name: string;
    stop_lat: number;
    stop_lon: number;
    popupContent?: string;
}

const busStopData: { [key: string]: BusStop[] } = {
    dotemachi: busStop_dotemachi as BusStop[],
    soma: busStop_soma as BusStop[],
    inakadate: busStop_inakadate as BusStop[],
};

export const getBusStops = (route: string): BusStop[] => {
    return busStopData[route];
};