import React from 'react';

interface HamburgerMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isOpen, toggleMenu }) => {
  return (
    <div
      className={`absolute top-4 right-4 w-8 h-8 flex flex-col justify-around items-center cursor-pointer z-1500 ${isOpen ? 'open' : ''}`}
      onClick={toggleMenu}
    >
      <div
        className={`absolute w-full h-0.5 bg-black transform transition duration-500 ease-in-out ${
          isOpen ? 'rotate-45 translate-y-0' : '-translate-y-2'
        }`}
      ></div>
      <div
        className={`absolute w-full h-0.5 bg-black transform transition duration-500 ease-in-out ${
          isOpen ? 'opacity-0' : 'opacity-100'
        }`}
      ></div>
      <div
        className={`absolute w-full h-0.5 bg-black transform transition duration-500 ease-in-out ${
          isOpen ? '-rotate-45 translate-y-0' : 'translate-y-2'
        }`}
      ></div>
    </div>
  );
};

export default HamburgerMenu;